// eslint-disable-next-line import/no-anonymous-default-export
export default `<svg
   width="20mm"
   height="20.000002mm"
   viewBox="0 0 20 20.000002"
   version="1.1"
   id="svg1"
   xml:space="preserve"
   xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
   xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
   xmlns="http://www.w3.org/2000/svg"
   xmlns:svg="http://www.w3.org/2000/svg"><sodipodi:namedview
     id="namedview1"
     pagecolor="#505050"
     bordercolor="#ffffff"
     borderopacity="1"
     inkscape:showpageshadow="0"
     inkscape:pageopacity="0"
     inkscape:pagecheckerboard="1"
     inkscape:deskcolor="#505050"
     inkscape:document-units="mm" /><defs
     id="defs1" /><g
     inkscape:label="Warstwa 1"
     inkscape:groupmode="layer"
     id="layer1"><circle
       style="fill:#14234c;fill-opacity:1;stroke-width:2.86134;stroke-linecap:round;stroke-linejoin:round;paint-order:markers fill stroke"
       id="path8"
       cx="10"
       cy="10"
       r="10"
       inkscape:export-filename="path8.svg"
       inkscape:export-xdpi="133.20605"
       inkscape:export-ydpi="133.20605" /><g
       style="fill:none"
       id="g1"
       transform="matrix(0.37476517,0,0,0.37476517,1.0989568,0.95325307)"><path
         d="m 19.973,35.228 -3.16,-0.8 -1.164,4.595 3.16,0.8 z"
         fill="#664aed"
         id="path1" /><path
         d="m 25.45,36.611 -3.16,-0.8 -1.165,4.595 3.16,0.8 1.164,-4.595 z m 6.972,-27.582 -3.16,-0.8 -1.164,4.595 3.16,0.8 z"
         fill="#146af4"
         id="path2" /><path
         d="m 26.973,7.631 -3.16,-0.8 -1.164,4.595 3.16,0.8 z"
         fill="#664aed"
         id="path3" /><path
         d="m 26.922,37 -16.32,-4.14 0.98,-3.88 16.32,4.14 c 1.4,0.36 2.8,-0.42 3.12,-1.72 0.32,-1.3 -0.54,-2.64 -1.94,-3 l -10.2,-2.58 0.98,-3.88 10.2,2.58 c 3.68,0.94 5.98,4.5 5.1,7.92 -0.88,3.42 -4.58,5.48 -8.26,4.54 z"
         fill="#b9b9fc"
         id="path4" /><path
         d="m 25.804,12.238 -5.331,-1.35 -5.828,23.014 5.332,1.35 z"
         fill="#664aed"
         id="path5" /><path
         d="m 27.502,25.94 -10.2,-2.58 0.98,-3.88 10.2,2.58 c 1.4,0.36 2.8,-0.42 3.12,-1.72 0.32,-1.3 -0.54,-2.64 -1.94,-3 l -14.24,-3.6 0.98,-3.88 14.24,3.6 c 3.68,0.94 5.98,4.5 5.1,7.92 -0.88,3.42 -4.58,5.48 -8.26,4.54 z"
         fill="#9ff7ec"
         id="path6" /><path
         d="m 25.78,12.224 -5.33,-1.35 -1.115,4.401 5.331,1.35 1.115,-4.4 z"
         fill="#664aed"
         id="path7" /></g></g></svg>`;
