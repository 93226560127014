// eslint-disable-next-line import/no-anonymous-default-export
export default `<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M39.4014 24.8382C36.7298 35.5525 25.8768 42.0731 15.16 39.4012C4.44764 36.73 -2.07368 25.8776 0.599101 15.164C3.26938 4.44845 14.1224 -2.07273 24.8361 0.598492C35.5522 3.26972 42.0729 14.1234 39.4014 24.8382Z" fill="url(#paint0_linear_3778_5981)"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M20.2896 7.0697C19.6227 6.891 18.9372 7.28678 18.7585 7.95371L18.2285 9.93146L16.4727 9.46097L12.3549 24.8287L11.1483 24.5054C10.4208 24.3105 9.67293 24.7422 9.47798 25.4698C9.28303 26.1973 9.7148 26.9452 10.4424 27.1401L11.6489 27.4634L11.6485 27.4649L13.8439 28.0531L13.3147 30.0281C13.136 30.6951 13.5317 31.3806 14.1987 31.5593C14.8656 31.738 15.5511 31.3422 15.7298 30.6753L16.259 28.7003L18.4572 29.2893L17.9279 31.2645C17.7492 31.9314 18.145 32.6169 18.8119 32.7956C19.4789 32.9743 20.1644 32.5785 20.3431 31.9116L20.8723 29.9364L21.6385 30.1417C24.2456 30.8403 26.9253 29.2931 27.6239 26.686C28.2727 24.2648 26.9845 21.7809 24.7092 20.8795L24.7095 20.8783C24.5797 20.8379 24.6025 20.652 24.7305 20.6378L24.7476 20.6369L24.7742 20.6368L24.7746 20.6354C26.5805 20.528 28.1953 19.2843 28.6892 17.4409L28.7186 17.3311C29.3522 14.9665 27.949 12.536 25.5844 11.9025L25.257 11.8147L25.7869 9.83718C25.9656 9.17025 25.5698 8.48473 24.9029 8.30603C24.236 8.12733 23.5504 8.52311 23.3717 9.19004L22.8419 11.1676L20.6437 10.5786L21.1736 8.60085C21.3523 7.93392 20.9565 7.2484 20.2896 7.0697ZM14.6689 11.8003C13.9414 11.6053 13.5096 10.8575 13.7045 10.1299C13.8995 9.40238 14.6473 8.97062 15.3749 9.16557L16.4727 9.45972L15.7667 12.0944L14.6689 11.8003ZM17.4433 17.6304C17.2136 16.7731 17.7223 15.8919 18.5796 15.6622L23.0764 14.4573C23.9337 14.2276 24.8149 14.7364 25.0446 15.5936C25.2743 16.4509 24.7655 17.3321 23.9083 17.5618L19.4114 18.7667C18.5542 18.9964 17.673 18.4877 17.4433 17.6304ZM15.3847 24.9019C15.155 24.0446 15.6637 23.1634 16.521 22.9337L21.0178 21.7288C21.8751 21.4991 22.7563 22.0078 22.986 22.8651C23.2157 23.7224 22.7069 24.6036 21.8497 24.8333L17.3528 26.0382C16.4956 26.2679 15.6144 25.7592 15.3847 24.9019Z" fill="white"/>
<defs>
<linearGradient id="paint0_linear_3778_5981" x1="20" y1="0" x2="20" y2="40" gradientUnits="userSpaceOnUse">
<stop stop-color="#FEDF70"/>
<stop offset="1" stop-color="#D88528"/>
</linearGradient>
</defs>
</svg>`;
