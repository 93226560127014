import { Environments } from '../types/global';
export const SOCIAL_LINKS = {
    DISCORD: 'https://discord.gg/sovryn',
    TELEGRAM: 'https://t.me/SovrynBitcoin',
    TWITTER: 'https://x.com/SovrynBTC',
};
export const GITHUB_LINKS = {
    ORGANIZATION: 'https://github.com/DistributedCollective',
    DAPP: 'https://github.com/DistributedCollective/sovryn-dapp',
};
export const WEBSITE_LINKS = {
    ROOT: 'https://sovryn.com',
    BLOG: 'https://sovryn.com/all-things-sovryn',
};
export const ALPHA_LINKS = {
    [Environments.Mainnet]: 'https://alpha.sovryn.app',
    [Environments.Testnet]: 'https://alpha-test.sovryn.app',
    STAGING: 'https://alpha-staging.sovryn.app',
};
export const BITOCRACY_LINKS = {
    [Environments.Mainnet]: 'https://bitocracy.sovryn.app',
    [Environments.Testnet]: 'https://bitocracy.test.sovryn.app',
};
export const STAGING_LINK = 'https://staging.sovryn.com';
export const WIKI_LINKS = {
    ROOT: 'https://wiki.sovryn.com',
    STABILITY_POOL: 'https://wiki.sovryn.com/sovryn-dapp/using-zero#earn-in-the-stability-pool',
    AMM_POOL: 'https://wiki.sovryn.com/sovryn-dapp/market-making',
    LEND: 'https://wiki.sovryn.com/sovryn-dapp/lending',
    TRADE: 'https://wiki.sovryn.com/sovryn-dapp/trading',
    BRIDGE: 'https://babelfish.gitbook.io/the-babelfish-gitbook/tutorials-and-guides/bridges',
    NOTIFICATIONS: 'https://wiki.sovryn.com/sovryn-dapp/using-zero#set-notifications',
    SECURITY: 'https://wiki.sovryn.com/technical-documents#security',
    FEES: 'https://wiki.sovryn.com/sovryn-dapp/fees#zero-borrowing',
    RISKS: 'https://wiki.sovryn.com/en/sovryn-dapp/subprotocols/zero-zusd#risks',
    STABILITY_POOL_REWARDS: 'https://wiki.sovryn.com/en/sovryn-dapp/subprotocols/zero-zusd#stability-pool-rewards',
    YIELD_FARMING: 'https://wiki.sovryn.com/en/sovryn-dapp/market-making#yield-farming',
    BORROWING: 'https://wiki.sovryn.com/sovryn-dapp/borrowing#basics-of-borrowing-on-sovryn',
    REWARDS: 'https://wiki.sovryn.app/en/sovryn-dapp/sovryn-rewards-explained',
    STAKING: 'https://wiki.sovryn.com/en/governance/staking-vesting-voting',
    WALLET_SETUP: 'https://wiki.sovryn.com/en/getting-started/wallet-setup',
    BRIDGE_RUNES: 'https://wiki.sovryn.com/en/sovryn-dapp/runes#bridging-runes',
};
export const HELPDESK_LINK = 'https://help.sovryn.app/';
export const BABELFISH_APP_LINK = 'https://app.babelfish.money';
export const POWA_LINK = 'https://sovryn.com/powa';
export const GOBOB_LINK = 'https://gobob.sovryn.app';
export const SEPOLIA_FAUCET_LINK = 'https://sepolia-faucet.pk910.de';
export const BOB_DOCS_LINK = 'https://docs.gobob.xyz/';
export const ROOTSTOCK_LINK = 'https://rootstock.io/';
