import React from 'react';
import { createBrowserRouter, createHashRouter, redirect, } from 'react-router-dom';
import { PageContainer } from './app/4_templates';
import { EmailVerificationStateContainer } from './app/4_templates/EmailVerificationStateContainer/EmailVerificationStateContainer';
import { earnPageLoader } from './app/5_pages/EarnPage/loader';
import { EmailDuplicateVerifiedPage } from './app/5_pages/EmailDuplicateVerifiedPage/EmailDuplicateVerifiedPage';
import { EmailErrorPage } from './app/5_pages/EmailErrorPage/EmailErrorPage';
import { EmailUnsubscribedAllPage } from './app/5_pages/EmailUnsubscribedAllPage/EmailUnsubscribedAllPage';
import { EmailUnsubscribedPage } from './app/5_pages/EmailUnsubscribedPage/EmailUnsubscribedPage';
import { EmailVerifiedPage } from './app/5_pages/EmailVerifiedPage/EmailVerifiedPage';
import { ErrorPage } from './app/5_pages/ErrorPage/ErrorPage';
import { zeroPageLoader } from './app/5_pages/ZeroPage/loader';
import { CrocContextProvider } from './contexts/CrocContext';
import { isIPFSBuild } from './utils/helpers';
import { loadable } from './utils/loadable';
const Zero = loadable(() => import('./app/5_pages/ZeroPage/ZeroPage'));
const EarnPage = loadable(() => import('./app/5_pages/EarnPage/EarnPage'));
const ConvertPage = loadable(() => import('./app/5_pages/ConvertPage/ConvertPage'));
const HistoryPage = loadable(() => import('./app/5_pages/HistoryPage/HistoryPage'));
const PrivacyPolicy = loadable(() => import('./app/5_pages/PrivacyPolicy/PrivacyPolicy'));
const TermsOfUse = loadable(() => import('./app/5_pages/TermsOfUse/TermsOfUse'));
const RewardsPage = loadable(() => import('./app/5_pages/RewardsPage/RewardsPage'));
const LendPage = loadable(() => import('./app/5_pages/LendPage/LendPage'));
const MarketMakingPage = loadable(() => import('./app/5_pages/MarketMakingPage/MarketMakingPage'));
const StakePage = loadable(() => import('./app/5_pages/StakePage/StakePage'));
const BorrowPage = loadable(() => import('./app/5_pages/BorrowPage/BorrowPage'));
const BitocracyPage = loadable(() => import('./app/5_pages/BitocracyPage/BitocracyPage'));
const ProposalPage = loadable(() => import('./app/5_pages/ProposalPage/ProposalPage'));
const LandingPage = loadable(() => import('./app/5_pages/LandingPage/LandingPage'));
const PortfolioPage = loadable(() => import('./app/5_pages/PortfolioPage/PortfolioPage'));
const ProtocolDataPage = loadable(() => import('./app/5_pages/ProtocolDataPage/ProtocolDataPage'));
const LeaderboardPointsPage = loadable(() => import('./app/5_pages/LeaderboardPointsPage/LeaderboardPointsPage'));
const ClaimLpPage = loadable(() => import('./app/5_pages/ClaimLpPage/ClaimLpPage'));
const RunesPage = loadable(() => import('./app/5_pages/RunesPage/RunesPage'));
const routes = [
    {
        path: '/',
        element: (React.createElement(PageContainer, { className: "flex flex-col", contentClassName: "justify-center" })),
        errorElement: React.createElement(ErrorPage, null),
        children: [
            {
                index: true,
                element: React.createElement(LandingPage, null),
            },
            {
                path: '/convert',
                element: React.createElement(ConvertPage, null),
            },
            {
                path: '/borrow/line-of-credit',
                element: React.createElement(Zero, null),
                loader: zeroPageLoader,
            },
            {
                path: '/borrow/fixed-interest',
                element: React.createElement(BorrowPage, null),
            },
            {
                path: '/borrow',
                loader: () => redirect('/borrow/fixed-interest'),
            },
            {
                path: '/earn/stability-pool',
                element: React.createElement(EarnPage, null),
                loader: earnPageLoader,
            },
            {
                path: '/earn/lend',
                element: React.createElement(LendPage, null),
            },
            {
                path: '/earn/market-making',
                element: React.createElement(MarketMakingPage, null),
            },
            {
                path: '/earn',
                loader: () => redirect('/earn/lend'),
            },
            {
                path: '/bitocracy',
                element: React.createElement(BitocracyPage, null),
            },
            {
                path: '/bitocracy/:id',
                element: React.createElement(ProposalPage, null),
            },
            {
                path: '/history',
                element: React.createElement(HistoryPage, null),
            },
            {
                path: '/rewards',
                element: React.createElement(RewardsPage, null),
                loader: zeroPageLoader,
            },
            {
                path: '/portfolio',
                element: (React.createElement(CrocContextProvider, null,
                    React.createElement(PortfolioPage, null))),
                loader: zeroPageLoader,
            },
            {
                path: '/earn/staking',
                element: React.createElement(StakePage, null),
            },
            {
                path: '/bob-lp-points',
                element: React.createElement(LeaderboardPointsPage, null),
            },
            {
                path: '/stats',
                element: React.createElement(ProtocolDataPage, null),
            },
            {
                path: '/claim-lp',
                element: React.createElement(ClaimLpPage, null),
            },
            {
                path: '/runes',
                element: React.createElement(RunesPage, null),
            },
        ],
    },
    {
        element: React.createElement(PageContainer, { contentClassName: "container" }),
        children: [
            {
                path: '/policies/terms-of-service',
                element: React.createElement(TermsOfUse, null),
            },
            {
                path: '/policies/privacy-policy',
                element: React.createElement(PrivacyPolicy, null),
            },
        ],
    },
    {
        element: React.createElement(EmailVerificationStateContainer, { contentClassName: "container" }),
        children: [
            {
                path: '/notifications/error',
                element: React.createElement(EmailErrorPage, null),
            },
            {
                path: '/notifications/verified',
                element: React.createElement(EmailVerifiedPage, null),
            },
            {
                path: '/notifications/duplicate',
                element: React.createElement(EmailDuplicateVerifiedPage, null),
            },
            {
                path: '/notifications/unsubscribed',
                element: React.createElement(EmailUnsubscribedPage, null),
            },
            {
                path: '/notifications/unsubscribed-all',
                element: React.createElement(EmailUnsubscribedAllPage, null),
            },
        ],
    },
];
const createRouter = isIPFSBuild() ? createHashRouter : createBrowserRouter;
export const router = createRouter(routes);
